.center {
  @apply flex justify-center items-center;
}

.card {
  @apply p-4 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700;
}

.input {
  @apply mt-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-4 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500;
}

.input-error {
  @apply mt-4 bg-gray-50 border border-red-600 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-4 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500;
}

.textarea {
  @apply w-full mt-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block ps-4 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500;
}

.textarea-error {
  @apply w-full mt-4 bg-gray-50 border border-red-600 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block ps-4 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500;
}

.select {
  @apply my-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block ps-4 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white;
}

.container {
  @apply w-full p-2 pb-14;
}

.button {
  @apply mx-1 bg-blue-500 hover:bg-blue-700 text-white text-sm font-bold py-1 px-4 rounded-full;
}

.button-cancel {
  @apply mx-1 bg-red-500 hover:bg-red-700 text-white text-sm font-bold py-1 px-4 rounded-full;
}

.button-disabled {
  @apply mx-1 bg-gray-400 text-white text-sm font-bold py-1 px-4 rounded-full;
}

.left-panel {
  @apply hidden showAll:block min-w-[280px] overflow-y-auto h-screen scrollbar-hidden bg-white border border-gray-200 dark:bg-gray-800 dark:border-gray-700;
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for other browsers */
.scrollbar-hidden {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
