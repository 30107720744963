.question-header {
  @apply w-full flex flex-row;
}

.user-info {
  @apply w-full flex flex-col;
}

.new-question-button {
  @apply flex justify-center items-center mt-1 mb-4 bg-blue-500 hover:bg-blue-700 text-white text-sm font-bold py-2 px-4 rounded-lg w-full;
}
