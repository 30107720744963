.question-header-menu-item {
  @apply block px-4 py-2 text-sm w-full hover:bg-gray-100 dark:hover:bg-gray-800 text-left;
}

.header-menu-container {
  @apply relative;
}

.header-menu-element {
  @apply absolute top-[-15px] left-[-150px] mt-2 w-40 bg-white border border-gray-200 dark:bg-[#1f2937] dark:border-gray-900 rounded shadow-lg z-10 panel:right-[340px];
}
