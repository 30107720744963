.comment-textarea-container {
  position: relative;
  width: 100%;
}

.comment-textarea {
  @apply w-full mt-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block ps-4 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500;
  padding-bottom: 3rem;
}

.comment-area-buttons {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.comment-textarea-button {
  @apply mx-1 bg-blue-500 text-white py-1 px-3 rounded-lg text-xs; /* Add Tailwind classes for styling */
}

.add-comment-button {
  @apply mt-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full ps-4 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white cursor-text text-left;
}
