.grid-item {
  @apply flex justify-center items-center border-2 border-gray-100 rounded-lg text-sm cursor-pointer hover:bg-onahu p-2;
}

.grid-item-selected {
  @apply flex justify-center items-center border-2 border-gray-100 rounded-lg text-sm bg-onahu p-2;
}

.grid-item-no-hover {
  @apply flex justify-center items-center border-2 border-gray-100 rounded-lg text-sm p-2;
}
