.range-slider {
  --c: #547980; /* slider color */
  --g: round(0.3em, 1px); /* the gap */
  --l: round(0.2em, 1px); /* line thickness*/
  --s: round(1.3em, 1px); /* thumb size*/
  --t: round(0.8em, 1px); /* tooltip tail size */
  --r: round(0.8em, 1px); /* tooltip radius */
  timeline-scope: --thumb-view;
  translate: 0;
}

.range-slider {
  width: 100%;
  height: var(--s); /* needed for Firefox*/
  --_c: color-mix(in srgb, var(--c), #000 var(--p, 0%));
  appearance: none;
  background: none;
  cursor: pointer;
  overflow: hidden;
  font-size: inherit;
}
.range-slider:focus-visible,
.range-slider:hover {
  --p: 25%;
}

.range-slider:active,
.range-slider:focus-visible {
  --_b: var(--s);
}

.range-slider:hover {
  opacity: 1;
}

/* Chrome */
.range-slider::-webkit-slider-thumb {
  height: var(--s);
  aspect-ratio: 1;
  border-radius: 50%;
  box-shadow: 0 0 0 var(--_b, var(--l)) inset var(--_c);
  border-image: linear-gradient(90deg, var(--_c) 50%, #ababab 0) 0 1 /
    calc(50% - var(--l) / 2) 100vw/0 calc(100vw + var(--g));
  -webkit-appearance: none;
  appearance: none;
  transition: 0.3s;
  anchor-name: --thumb;
  view-timeline: --thumb-view inline;
  view-timeline-inset: var(--s);
}

/* Firefox */
.range-slider::-moz-range-thumb {
  height: var(--s);
  width: var(--s);
  background: none;
  border-radius: 50%;
  box-shadow: 0 0 0 var(--_b, var(--l)) inset var(--_c);
  border-image: linear-gradient(90deg, var(--_c) 50%, #ababab 0) 0 1 /
    calc(50% - var(--l) / 2) 100vw/0 calc(100vw + var(--g));
  -moz-appearance: none;
  appearance: none;
  transition: 0.3s;
  anchor-name: --thumb;
  view-timeline: --thumb-view inline;
  view-timeline-inset: var(--s);
}
