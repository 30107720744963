.nav {
  @apply bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 p-3 z-10 min-w-[320px];
}

.auth-header-menu-item {
  @apply block px-4 py-2 text-sm w-full hover:bg-gray-100 dark:hover:bg-gray-900 text-left;
}

.switch {
  @apply relative inline-block w-12 h-6;
}

.switch input {
  @apply opacity-0;
}

.slider {
  @apply absolute cursor-pointer top-0 left-0 right-0 bottom-0 bg-gray-300 transition duration-[400];
}

.slider:before {
  @apply absolute bg-white transition duration-[400] w-4 h-4 left-1 bottom-1;
  content: "";
}

input:checked + .slider {
  @apply bg-blue-500;
}

input:focus + .slider {
  @apply shadow;
}

input:checked + .slider:before {
  @apply transform translate-x-6;
}

.slider.round {
  @apply rounded-full;
}

.slider.round:before {
  @apply rounded-full;
}
