::-webkit-scrollbar {
  width: 8px; /* width of the scrollbar */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; /* color of the scrollbar handle */
  border-radius: 10px; /* roundness of the scrollbar handle */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* color of the scrollbar handle on hover */
}
