.toggle-label {
  width: 70px;
  height: 35px;
  position: relative;
  display: block;
  background: lightblue;
  border-radius: 200px;
  box-shadow: inset 0px 5px 15px rgba(0, 0, 0, 0.4),
    inset 0px -5px 15px rgba(255, 255, 255, 0);
  cursor: pointer;
  transition: 0.3s;
}

.toggle-label .icon {
  position: absolute;
  top: 50%;
  left: 10px; /* Adjust the left position as needed */
  transform: translateY(-50%);
}

.toggle-label:after {
  display: block;
  position: absolute;
  content: "";
  width: 30px;
  height: 30px;
  top: 3px;
  left: 5px;
  background: white;
  border-radius: 25px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}
.toggle-input {
  width: 0;
  height: 0;
  visibility: hidden;
}

.toggle-input:checked + .toggle-label:after {
  left: 5px;
  transform: translateX(100%);
}
.toggle-label:active:after {
  width: 35px;
}
