@tailwind base;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --toastify-color-progress-light: #3b82f6;
  --toastify-color-progress-dark: #3b82f6;
  overflow: hidden;
  @apply bg-[#f9f9f9] dark:bg-[#1f1f1f] dark:text-[#f2f4f5] text-sm;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
